import React, { useState, useEffect, useRef, useMemo } from 'react';
import { AiOutlineCloudUpload, AiOutlinePlus } from "react-icons/ai";
import Papa from 'papaparse';
import './EditableTable.css';

const EditableTable = ({
  data,
  onDataChange,
  setSelectedColumns,
  weights,
  setWeights,
  paginatedData,
  currentPage,
  handlePageChange,
  dataPerPage,
  setUploadStatus,
  pageName,
  setPageName
}) => {
  const [inputContent, setInputContent] = useState('#topic: \n\n#content: ');
  const [selectedCell, setSelectedCell] = useState(null);
  const [editingContent, setEditingContent] = useState('');
  const textareaRef = useRef(null);
  const [showModal, setShowModal] = useState(data.length === 0);

  useEffect(() => {
    if (data.length === 0) {
      setShowModal(true);
    } else {
      setShowModal(false);
      setSelectedColumns({ metric: 'total context', content: 'total context' });
      updateTextAreaContent(data);
    }
  }, [data, setSelectedColumns]);

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const newPageName = file.name.replace('.csv', '').replace(/_/g, ' ');
      Papa.parse(file, {
        header: true,
        dynamicTyping: true,
        complete: (results) => {
          const updatedData = results.data.filter(row => Object.values(row).some(value => value && value.toString().trim() !== ""));
          onDataChange(updatedData);
          if (updatedData.length > 0) {
            updateTextAreaContent(updatedData);
            setWeights({ 'total context': 1 });
          }
          setPageName(newPageName);
        }
      });
    }
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    if (file && file.type === 'text/csv') {
      handleFileUpload({ target: { files: [file] } });
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const EmptyTableModal = () => (
    <div className="empty-modal" onDrop={handleDrop} onDragOver={handleDragOver}>
      <div className="empty-modal-content">
        <h2>No Data Available</h2>
        <p>Click to upload or drag and drop your CSV file here.</p>
        <label className="upload-button">
          <AiOutlineCloudUpload size={50} />
          <input type="file" style={{ display: 'none' }} onChange={handleFileUpload} />
        </label>
        <div className="drag-drop-area">
          <div className="drag-drop-border">
            <p>Drag and drop here</p>
          </div>
        </div>
      </div>
    </div>
  );

  const updateTextAreaContent = (data) => {
    const headers = data.length > 0 ? Object.keys(data[0]) : [];
    const nonTimeHeaders = headers.filter(header => header !== 'time' && header !== 'topic' && header !== 'content');

    const topicLine = inputContent.split('\n').find(line => line.startsWith('#topic:'));
    const topicValue = topicLine ? topicLine.split('#topic: ')[1] : '';

    const initialContent = `#topic: ${topicValue}\n\n#content: \n\n${nonTimeHeaders.map(header => `#${header}: `).join('\n\n')}`;
    setInputContent(initialContent);

    setTimeout(() => {
      const textarea = document.querySelector("textarea");
      if (textarea) {
        const contentIndex = initialContent.indexOf('#content: ') + 10;
        textarea.setSelectionRange(contentIndex, contentIndex);
        textarea.focus();
      }
    }, 0);
  };

  const handleCellClick = (rowIndex, colIndex) => {
    const headers = Object.keys(paginatedData[0]);
    if (selectedCell && editingContent !== '') {
      handleCellBlur();
    }
    setSelectedCell({ row: rowIndex, col: colIndex });
    setEditingContent(paginatedData[rowIndex][headers[colIndex]]);
  };

  const handleCellChange = (e) => {
    setEditingContent(e.target.value);
  };

  const handleCellBlur = () => {
    if (selectedCell) {
      const updatedData = [...data];
      const headers = Object.keys(data[0]);
      const { row, col } = selectedCell;

      const fullDataRowIndex = (currentPage - 1) * dataPerPage + row;

      updatedData[fullDataRowIndex][headers[col]] = editingContent;
      onDataChange(updatedData);
      setSelectedCell(null);
      setEditingContent('');
    }
  };

  const headers = useMemo(() => {
    const initialHeaders = ['time', 'topic', 'content', 'total context'];
    const currentHeaders = paginatedData.length > 0 ? Object.keys(paginatedData[0]) : initialHeaders;
    const remainingHeaders = currentHeaders.filter(header => !initialHeaders.includes(header));
    return [...initialHeaders, ...remainingHeaders];
  }, [paginatedData]);

  const totalPages = Math.ceil(data.length / dataPerPage);

  const addPost = () => {
    const lines = inputContent.split('\n');
    const postData = {};
  
    let currentKey = '';
    lines.forEach(line => {
      const match = line.match(/^#([\w\s]+):\s*(.*)/);
      if (match) {
        currentKey = match[1].trim();
        postData[currentKey] = match[2].trim();
      } else if (currentKey) {
        postData[currentKey] += `\n${line.trim()}`;
      }
    });
  
    postData.time = new Date().toLocaleString();
  
    postData['total context'] = Object.keys(postData)
      .filter(key => key !== 'total context')
      .map(key => `${key}: ${postData[key]}`)
      .join('\n\n');
  
    const initialHeaders = ['time', 'topic', 'content', 'total context'];
    const newHeaders = [...new Set([...initialHeaders, ...Object.keys(postData), ...Object.keys(data[0] || {})])];
  
    const updatedData = [...data.map(row => {
      const newRow = {};
      newHeaders.forEach(header => {
        newRow[header] = row[header] || '';
      });
      return newRow;
    }), postData];
  
    onDataChange(updatedData);
    updateTextAreaContent(updatedData);
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      if (e.shiftKey || e.metaKey) {
        setInputContent(inputContent + '\n');
      } else {
        e.preventDefault();
        addPost();
      }
    }
  };

  return (
    <div className="container">
      {showModal && <EmptyTableModal />}
      {!showModal && (
        <div className="right-panel">
          <div className="table-container">
            <table>
              <thead>
                <tr>
                  {headers.map((header, colIndex) => (
                    <th
                      key={colIndex}
                      className={`header-cell ${header === 'total context' ? 'selected-context' : ''}`}
                    >
                      {header}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {paginatedData.map((post, rowIndex) => (
                  <tr key={rowIndex}>
                    {headers.map((header, colIndex) => (
                      <td
                        key={colIndex}
                        className={`cell ${selectedCell?.row === rowIndex && selectedCell?.col === colIndex ? 'selected-cell' : ''} ${header === 'total context' ? 'selected-context' : ''}`}
                        onClick={() => handleCellClick(rowIndex, colIndex)}
                        style={{ whiteSpace: 'pre-wrap' }}
                      >
                        {selectedCell?.row === rowIndex && selectedCell?.col === colIndex ? (
                          <textarea
                            value={editingContent}
                            onChange={handleCellChange}
                            onBlur={handleCellBlur}
                            autoFocus
                          />
                        ) : (
                          post[header]
                        )}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="pagination-container">
            {currentPage > 1 && (
              <button className="pagination-button" onClick={() => handlePageChange(currentPage - 1)}>
                &lt;
              </button>
            )}
            <span className="pagination-current">{currentPage}</span>
            {currentPage < totalPages && (
              <button className="pagination-button" onClick={() => handlePageChange(currentPage + 1)}>
                &gt;
              </button>
            )}
          </div>
          
          {/* <div className="input-container">
            <textarea
              value={inputContent}
              onChange={(e) => setInputContent(e.target.value)}
              onKeyDown={handleKeyDown}
              placeholder="Write something here..."
            />
            <div className="icon-container">
              <label>
                <AiOutlineCloudUpload className="icon" />
                <input type="file" style={{ display: 'none' }} onChange={handleFileUpload} />
              </label>
              <div className="add-button" onClick={addPost}>
                <AiOutlinePlus />
              </div>
            </div>
          </div> */}

        </div>
      )}
    </div>
  );
};

export default EditableTable;
