import React, { useState } from 'react';

const BASE_URL = 'https://api.triangl.ai'; 
// const BASE_URL = 'http://localhost:5000';

const ChatPanel = ({ graphData, setSelectedNodes, pageName, setPageName }) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [chatLog, setChatLog] = useState([]);
  const [showTopResults, setShowTopResults] = useState(false);

  const handleSearchInput = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleSendQuery = async () => {
    if (!searchQuery) return;
  
    try {
      const response = await fetch(`${BASE_URL}/agent`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ query: searchQuery }),
      });
      const result = await response.json();
  
      const matchingNodeIds = graphData.nodes
        .filter(node => result.matches.some(match => match.content === node.content))
        .map(node => node.id);
  
      setSelectedNodes(matchingNodeIds);
      setChatLog(prevLog => [
        ...prevLog,
        { type: 'query', text: searchQuery },
        { type: 'response', text: formatTranscript(result.response) },
        { type: 'results', results: result.matches }
      ]);
      setSearchQuery('');
    } catch (error) {
      console.error('Error fetching search results:', error);
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleSendQuery();
    }
  };

  const formatTranscript = (text) => {
    const boldPattern = /\*\*(.*?)\*\*/g;
    const italicPattern = /\*(.*?)\*/g;
    const breakPattern = /###(.*?)###/g;

    let formattedText = text
      .replace(boldPattern, (match, p1) => `<strong>${p1}</strong>`)
      .replace(italicPattern, (match, p1) => `<em>${p1}</em>`)
      .replace(breakPattern, (match, p1) => `<br/><br/>${p1}<br/><br/>`);

    return <div dangerouslySetInnerHTML={{ __html: formattedText }} />;
  };

  return (
    <div style={styles.chatContainer}>
      <div style={styles.pageNameContainer}>
        <input
          type="text"
          value={pageName}
          onChange={(e) => setPageName(e.target.value)}
          style={styles.pageNameInput}
        />
      </div>
      <div style={styles.chatLog}>
        {chatLog.map((entry, index) => (
          <div key={index} style={styles.chatEntry}>
            {entry.type === 'query' ? (
              <div style={styles.queryEntry}><strong>User:</strong> {entry.text}</div>
            ) : entry.type === 'response' ? (
              <div style={styles.responseEntry}><strong>Agent:</strong> {entry.text}</div>
            ) : (
              <div style={styles.resultsEntry}>
                {!showTopResults && (
                  <button onClick={() => setShowTopResults(true)} style={styles.showButton}>Show Top Results</button>
                )}
                {showTopResults && entry.results.map((result, idx) => (
                  <div key={idx} style={styles.result}>
                    <p style={styles.resultContent}>{result.content}</p>
                    <p style={styles.resultScore}>Match: {result.score.toFixed(3)}</p>
                  </div>
                ))}
              </div>
            )}
          </div>
        ))}
      </div>
      <div style={styles.inputContainer}>
        <input
          type="text"
          value={searchQuery}
          onChange={handleSearchInput}
          onKeyDown={handleKeyDown}
          placeholder="Type a query..."
          style={styles.input}
        />
        <button onClick={handleSendQuery} style={styles.sendButton}>Ask</button>
      </div>
    </div>
  );
};

const styles = {
  chatContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100vh',
    padding: '30px',
  },
  pageNameContainer: {
    display: 'flex',
    justifyContent: 'left',
    alignItems: 'left',
    marginBottom: '20px',
  },
  pageNameInput: {
    fontSize: '1.5rem',
    fontWeight: 'bold',
    border: 'none',
    outline: 'none',
    textAlign: 'left',
  },
  chatContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100vh',
    borderRadius: '20px 20px 0 0',
    padding: '30px',    
    boxShadow: '0 12px 24px rgba(0,0,0,0.1)',
    transition: 'all 0.3s ease',
  },
  chatLog: {
    flexGrow: 1,
    overflowY: 'auto',
    marginBottom: '20px',
    paddingRight: '20px',
    borderRadius: '15px',
    boxShadow: 'inset 0 4px 8px rgba(0,0,0,0.05)',
  },
  chatEntry: {
    marginBottom: '15px',
    transition: 'background-color 0.3s ease, transform 0.2s ease',
    padding: '15px',
    borderRadius: '15px',
    boxShadow: '0 4px 12px rgba(0,0,0,0.05)',
  },
  queryEntry: {
    color: '#3B3B3B', // Space gray text
    fontSize: '1.1em',
  },
  responseEntry: {
    color: '#3B3B3B', // Space gray text
    fontSize: '1.1em',
  },
  resultsEntry: {
    backgroundColor: '#FFFFFF',
    padding: '15px',
    borderRadius: '15px',
    boxShadow: '0 4px 12px rgba(0,0,0,0.05)',
  },
  result: {
    padding: '10px',
    marginTop: '10px',
    borderBottom: '1px solid #ECECEC',
  },
  resultContent: {
    fontWeight: 'bold',
    color: '#3B3B3B', // Space gray text
  },
  resultScore: {
    fontSize: '0.9em',
    color: '#888',
  },
  inputContainer: {
    display: 'flex',
    alignItems: 'center',
    padding: '15px',
    backgroundColor: '#FFFFFF',
    borderRadius: '30px',
    boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
  },
  input: {
    flexGrow: 1,
    padding: '15px 20px',
    borderRadius: '30px',
    border: '1px solid #DDD',
    outline: 'none',
    fontSize: '1.1em',
    backgroundColor: '#FFF',
    color: '#555',
  },
  sendButton: {
    padding: '12px 25px',
    backgroundColor: '#3B3B3B', // Space gray button
    color: 'white',
    border: 'none',
    borderRadius: '30px',
    marginLeft: '15px',
    cursor: 'pointer',
    transition: 'background-color 0.3s ease, transform 0.2s ease',
  },
  sendButtonHover: {
    backgroundColor: '#2C2C2C', // Darker blend for hover
    transform: 'scale(1.05)',
  },
  showButton: {
    backgroundColor: '#FFFFFF',
    color: '#3B3B3B', // Space gray button text
    border: '2px solid #3B3B3B', // Space gray border
    borderRadius: '20px',
    padding: '10px 15px',
    cursor: 'pointer',
    marginTop: '15px',
    textAlign: 'center',
    display: 'inline-block',
    transition: 'all 0.3s ease',
  },
  showButtonHover: {
    backgroundColor: '#3B3B3B', // Space gray background on hover
    color: '#FFFFFF',
    border: 'none',
  },
};

export default ChatPanel;
