import React, { useState, useEffect } from 'react';
import Table from './Table_classic';
import Agent from './Agent';

import './Tabs.css';
import logo from './assets/full-logo.svg';
import { CiViewTable } from 'react-icons/ci';

const BASE_URL = 'https://api.triangl.ai'; 
// const BASE_URL = 'http://localhost:5000';

const MainLayout = () => {
  const [tableData, setTableData] = useState([]);
  const [graphData, setGraphData] = useState({ nodes: [], links: [] });
  const [selectedNodes, setSelectedNodes] = useState([]);
  const [clusterNames, setClusterNames] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [dataPerPage] = useState(10);
  const [weights, setWeights] = useState({});
  const [uploadStatus, setUploadStatus] = useState('');
  const [selectedColumns, setSelectedColumns] = useState({ metric: '', content: '' });
  const [pageName, setPageName] = useState('new page');
  const [activeTab, setActiveTab] = useState('mindmap');
  const [isSplitScreen] = useState(true);

  const [isTableActive, setIsTableActive] = useState(true); // Set default to true

  const handleDataChange = (newData, newWeights = weights) => {
    setTableData(newData);
    setWeights(newWeights);
  };

  useEffect(() => {
    const fetchData = async () => {
      if (tableData.length > 0) {
        await fetchClusters(tableData);
      }
    };
    fetchData();
  }, [weights, selectedColumns]);

  const fetchClusters = async (data) => {
    try {
      const response = await fetch(`${BASE_URL}/api/cluster-classic`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ tableData: data, pagename: pageName || 'default_page', weights, selectedColumns }),
      });
      const responseData = await response.json();
      setGraphData(responseData);
      if (JSON.stringify(responseData.tableData) !== JSON.stringify(tableData)) {
        setTableData(responseData.tableData);
      }
    } catch (error) {
      console.error('Error fetching clusters:', error);
    }
  };

  const handlePageChange = (pageNumber) => setCurrentPage(pageNumber);
  const indexOfLastData = currentPage * dataPerPage;
  const indexOfFirstData = indexOfLastData - dataPerPage;
  const currentTableData = tableData.slice(indexOfFirstData, indexOfLastData);

  const handlePageNameChange = (e) => {
    setPageName(e.target.value);
  };

  // const saveData = async () => {
  //   const fileName = (pageName || 'default_page').replace(/\s+/g, '_') + '.csv';
  //   try {
  //     const response = await fetch(`${BASE_URL}/save`, {
  //       method: 'POST',
  //       headers: { 'Content-Type': 'application/json' },
  //       body: JSON.stringify({ data: tableData, fileName }),
  //     });
  //     const result = await response.json();
  //     if (response.ok) {
  //       alert(result.message);
  //     } else {
  //       alert(result.error);
  //     }
  //   } catch (error) {
  //     console.error('Error saving data:', error);
  //   }
  // };

  const renderActiveComponent = () => {
    if (isTableActive) {
      return (
        <Table
          data={tableData}
          onDataChange={handleDataChange}
          selectedColumns={selectedColumns}
          setSelectedColumns={setSelectedColumns}
          weights={weights}
          setWeights={setWeights}
          paginatedData={currentTableData}
          currentPage={currentPage}
          handlePageChange={handlePageChange}
          dataPerPage={dataPerPage}
          setUploadStatus={setUploadStatus}
          pageName={pageName}
          setPageName={setPageName}
          // saveData={saveData}
        />
      );
    } else {
      // return <GraphMap data={graphData} pageName={pageName} />;
    }
  };

  return (
    <div style={{ display: 'flex', height: '100vh', flexDirection: 'column' }}>
      
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: '10px', backgroundColor: '#fff' }}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <img src={logo} alt="Logo" style={{ marginLeft: '10px', height: '24px' }} />
        </div>

        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div
            style={{
              cursor: 'pointer',
              marginRight: '10px',
              borderRadius: '5px',
              boxShadow: isTableActive ? '0px 4px 12px rgba(0, 0, 0, 0.2)' : 'none',
            }}
            onClick={() => setIsTableActive(true)}
          >
            <CiViewTable size={24} />
          </div>
          <div
            style={{
              cursor: 'pointer',
              marginRight: '10px',
              borderRadius: '5px',
              boxShadow: !isTableActive ? '0px 4px 12px rgba(0, 0, 0, 0.2)' : 'none',
            }}
            onClick={() => setIsTableActive(false)}
          >
            {/* <PiGraph size={24} /> */}
          </div>
        </div>
      </div>

      <div style={{ display: 'flex', height: '100%' }}>
        <div style={{ width: '50%', height: '100%', overflow: 'auto' }}>
          <Agent graphData={graphData} setSelectedNodes={setSelectedNodes} pageName={pageName} setPageName={setPageName} /> 
        </div>

        <div style={{ width: '50%', height: '100%', overflow: 'auto', padding: '10px' }}>
          {renderActiveComponent()}
        </div>
      </div>
    </div>
  );
};

export default MainLayout;